/**

  This SCSS file contains colors definitions
  @see https://www.figma.com/file/EzdNTVM6tvmDBlbQhk3t22/Foundations-VMW?node-id=1%3A1581

 */
//TODO:review this as the colors should match what we have in figma - and if this is not there, we would need to revisit it with Aleks

// Product
$color-product-light: #F3FAFB;
$color-product-light-hover: #BBDFE6;
$color-product-light-active: #89C1CB;
$color-product-normal: #337A87;
$color-product-normal-hover: #2B636D;
$color-product-normal-active: #224C54;
$color-product-dark: #173F46;

// White
$color-white: #FFFFFF;

// Neutral
$color-neutral-lighter: #F9F9F9;
$color-neutral-light: #EEEEEE;
$color-neutral-light-hover: #E8E8E8;
$color-neutral-light-active: #DBDBDB;
$color-neutral-normal: #E0E0E0;
$color-neutral-dark: #C4C4C4;
$color-neutral-dark-hover: #ABABAB;
$color-neutral-dark-active: #5E5E5E;

// Ink
$color-ink-lighter: #8B8F94;
$color-ink-light: #585C63;
$color-ink-normal: #252A31;

// Blue
$color-blue-light: #EDF5FC;
$color-blue-light-hover: #DFE9F2;
$color-blue-light-active: #CBDBE9;
$color-blue-normal: #364A74;
$color-blue-normal-hover: #2D3D5E;
$color-blue-normal-active: #283653;
$color-blue-dark: #1F293E;

// Green
$color-green-light: #F2FFFA;
$color-green-light-hover: #D4FEEF;
$color-green-light-active: #B6FBE2;
$color-green-normal: #11B483;
$color-green-normal-hover: #10916A;
$color-green-normal-active: #0E6F52;
$color-green-dark: #0B4E3A;

// Red
$color-red-light: #FFF3F3;
$color-red-light-hover: #FFBDBD;
$color-red-light-active: #FF9191;
$color-red-normal: #EE5454;
$color-red-normal-hover: #C14747;
$color-red-normal-active: #AA4040;
$color-red-dark: #692929;

// Yellow
$color-yellow-light: #FFFBF2;
$color-yellow-light-hover: #F8E6B6;
$color-yellow-light-active: #E9CD79;
$color-yellow-normal-light:#F8CA48;
$color-yellow-normal: #B18E23;
$color-yellow-normal-hover: #8F731E;
$color-yellow-normal-active: #6E5919;
$color-yellow-dark: #4D3F13;
