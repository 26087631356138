@import 'utils';

/**

  This SCSS file contains typography definitions
  @see https://www.figma.com/file/EzdNTVM6tvmDBlbQhk3t22/Foundations-VMW?node-id=13%3A1938

 */

/*
  FONT FAMILY
 */

$document-font-family: Montserrat, sans-serif;

/*
  HEADING
 */

// Heading 1
$typography-h1-font-size: rem(40px);
$typography-h1-font-weight: 700;
$typography-h1-line-height: rem(60px);

// Heading 2
$typography-h2-font-size: rem(32px);
$typography-h2-font-weight: 700;
$typography-h2-line-height: rem(48px);

// Heading 3
$typography-h3-font-size: rem(24px);
$typography-h3-font-weight: 700;
$typography-h3-line-height: rem(36px);

// Heading 4
$typography-h4-font-size: rem(20px);
$typography-h4-font-weight: 700;
$typography-h4-line-height: rem(30px);

// Heading 5
$typography-h5-font-size: rem(16px);
$typography-h5-font-weight: 700;
$typography-h5-line-height: rem(24px);

// Heading 6
$typography-h6-font-size: rem(14px);
$typography-h6-font-weight: 700;
$typography-h6-line-height: rem(21px);

// Heading 7
$typography-h7-font-size: rem(12px);
$typography-h7-font-weight: 700;
$typography-h7-line-height: rem(18px);

/*
  BODY
 */

// Body Large
$typography-body1-font-size: rem(16px);
$typography-body1-font-weight: 400;
$typography-body1-line-height: rem(24px);

// Body Medium
$typography-body2-font-size: rem(14px);
$typography-body2-font-weight: 400;
$typography-body2-line-height: rem(21px);

// Body Small
$typography-body3-font-size: rem(12px);
$typography-body3-font-weight: 400;
$typography-body3-line-height: rem(18px);

// Body Extra Small
$typography-body4-font-size: rem(10px);
$typography-body4-font-weight: 400;
$typography-body4-line-height: rem(15px);

/*
  EMPHASIZED
 */

// Emphasized Large
$typography-em1-font-size: rem(16px);
$typography-em1-font-weight: 600;
$typography-em1-line-height: rem(24px);

// Emphasized Medium
$typography-em2-font-size: rem(14px);
$typography-em2-font-weight: 600;
$typography-em2-line-height: rem(21px);

// Emphasized Small
$typography-em3-font-size: rem(12px);
$typography-em3-font-weight: 600;
$typography-em3-line-height: rem(18px);

// Emphasized Extra Small
$typography-em4-font-size: rem(10px);
$typography-em4-font-weight: 600;
$typography-em4-line-height: rem(15px);

/*
  LINK
 */

// Link Large
$typography-link1-font-size: rem(16px);
$typography-link1-font-weight: 500;
$typography-link1-line-height: rem(24px);

// Link Medium
$typography-link2-font-size: rem(14px);
$typography-link2-font-weight: 500;
$typography-link2-line-height: rem(21px);

// Link Small
$typography-link3-font-size: rem(12px);
$typography-link3-font-weight: 500;
$typography-link3-line-height: rem(18px);

// Link Extra Small
$typography-link4-font-size: rem(10px);
$typography-link4-font-weight: 500;
$typography-link4-line-height: rem(15px);
