// Variables
@import "../variables/colors";
@import "../variables/general";
@import "../variables/typography";
@import "../variables/bootstrap";

.notification-provider {
  display: block;
}

.notification-main {
  z-index: 2050;
  position: fixed;
  left: 0;
  width: 100%;
  top: $menu-height;
  @include media-breakpoint-down(md) {
    top: $menu-height-mobile;
  }
  &.notification-main-top {
    top: 0;
  }
}
