// Variables
@import "../variables/colors";

.checkbox {
  box-sizing: border-box;
  display: block;

  .checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: calc(18px + 0.5rem);
    min-height: 18px;
    color: $color-black;

    &.checkbox-disabled {
      cursor: auto;
    }

    .checkbox-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin: 0;
    }

    .checkbox-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $color-gray;
      border-radius: 4px;
      background-color: $color-white;

      &.focused {
        border: 1px solid $color-black;
      }
    }

    &:not(.checkbox-disabled):hover {
      .checkbox-input ~ .checkbox-mark {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &.checkbox-disabled {
      .checkbox-mark {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .checkbox-mark:after {
      content: "";
      position: absolute;
      display: none;
    }

    & .checkbox-input:checked ~ .checkbox-mark:after {
      display: block;
    }

    .checkbox-mark:after {
      height: 18px;
      width: 18px;
      background-image: url('~src/assets/icon/checkbox.svg');
      background-position: center;
      transform: scale(0.8);
      background-repeat: no-repeat;
    }
  }

  @include media-breakpoint-down(md) {
    .checkbox-label {
      padding-left: calc(26px + 0.5rem);

      .checkbox-mark {
        height: 28px;
        width: 28px;
      }

      .checkbox-mark:after {
        height: 26px;
        width: 26px;
        transform: scale(1);
      }
    }
  }

  &.checkbox-error {
    .checkbox-label {
      * {
        color: $color-error !important;
      }
      .checkbox-mark {
        border: 1px solid $color-error;
      }
    }
  }
}
