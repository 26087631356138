@import "src/styles/variables/colors";
@import "src/styles/variables/bootstrap";
@import "src/styles/variables/general";

.appbar {
  .appbar-fixed-placeholder {
    position: static;
    display: block;
    visibility: hidden;
  }

  .appbar-height {
    width: 100%;
    height: $menu-height;
  }
  @include media-breakpoint-down(md) {
    .appbar-height {
      height: $menu-height-mobile;
    }
  }
  .appbar-container {
    z-index: 1;
    background-color: $color-white;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);

    .appbar-content {
      height: 100%;
    }
  }

  &.appbar-position-top {
    .appbar-container {
      position: fixed;
      top: 0;
      left: 0;
      border-radius: 0 0 10px 10px;
    }
  }
  &.appbar-position-bottom {
    .appbar-container {
      position: fixed;
      bottom: 0;
      border-radius: 10px 10px 0 0;
    }
  }

  .appbar-content {
    display: flex;
    align-items: center;
    margin: 0 auto;

  }
}

.appbar-item {
  height: inherit;
  display: flex;
  align-items: center;

  .icon {
    vertical-align: middle;
  }

  &.appbar-left {
    margin-left: 0;
    margin-right: auto;
  }

  &.appbar-right {
    margin-right: 0;
    margin-left: auto;
  }
}

.navbar-hamburger-fixed {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.navbar-hamburger-space-content {
  height: $menu-height;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  display: flex;

  @include media-breakpoint-down(md) {
    height: $menu-height-mobile;
  }

  .navbar-content {
    background: $color-white;
    margin-left: 8px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 8px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;

    .button {
      margin-top: 6px;
    }
  }
}
