@import "../variables/colors";
@import "../variables/typography";

.mobile-tab-group {

  display: flex;
  height: 70px;
  background: $color-white;
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);

  .mobile-tab {
    cursor: pointer;
    margin-right: 1rem;
    color: $color-darkgray;
    .mobile-tab-content {
      height: 70px;
      max-height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .mobile-tab-label {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: $subheading1-font-size;
        font-weight: $subheading1-font-weight;
        line-height: $subheading1-line-height;
      }
      &:hover:not(.mobile-tab-selected) {
        .mobile-tab-label {
          color: $color-primary;
        }
      }
      &.mobile-tab-selected {
        color: $color-primary;
        border-top: 2px solid transparent;
        border-bottom: 2px solid $color-primary;
      }
    }
  }

}
