@import 'styles/template';
@import 'styles2/template';
@import 'src/styles/variables/bootstrap';


.cdk-overlay-container {
  z-index: 2000 !important;
}

// Override all types of snowflakes by our own one
.snowflake__inner_type {
  &_0::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }

  &_1::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }

  &_2::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }

  &_3::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }

  &_4::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }

  &_5::before {
    background-image: url('~src/assets/img/home/snowflake.svg') !important;
  }
}

.mat-mdc-floating-label-wrapper {
  top: 3px;
}

.mat-mdc-progress-spinner circle, .mat-mdc-spinner circle {
  stroke: $color-primary;
}

.hsds-beacon .bbHIsf {
  @include media-breakpoint-down(md) {
    bottom: 70px !important;
    right: 16px !important;
  }
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}
