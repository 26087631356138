@import "../variables/colors";
@import "../variables/typography";
@import "../variables/spacing";

.stepper-container {
  display: flex;
  align-items: center;

  .stepper-button {
    color: $color-white;
    display: inherit;
    justify-content: center;
    align-items: center;
    background: $color-secondary;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    cursor: pointer;
    border: none;

    &:hover {
      background: $color-secondary-dark;
    }

    &:active {
      background: $color-secondary-mint;
    }

    &:disabled {
      background: $color-gray;
      cursor: default;
    }
  }

  .stepper-value {
    height: 1.5rem;
    width: 39px;
    text-align: center;
  }
}
