// Variables
@import "../variables/colors";
@import "../variables/typography";

.avatar {
  border-radius: 50%;
  background: $color-gray;
  color: $color-white;
  display: block;

  &.avatar-size-small {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }

  &.avatar-size-medium {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }

  &.avatar-size-large {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }

  .avatar-button {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    color: inherit;
    font-size: inherit;
    display: block;
    position: relative;
    font-family: inherit;
  }

  .avatar-content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;

    &.pointer {
      cursor: pointer;
    }

    &:hover:not(.hover-disabled)  {
      background: linear-gradient(0deg, rgba(54, 74, 116, 0.4), rgba(54, 74, 116, 0.4));
      border-radius: 50%;
    }
  }

  .avatar-image {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .avatar-text {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .avatar-content-settings {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 50%;
    overflow: hidden;
  }

  .avatar-settings {
    position: relative;
    height: 50%;
    background: rgba($color-black, 0.5);
    padding: 10% 5% 0 5%;

    .avatar-settings-text {
      color: $color-white;
      cursor: pointer;
      background: transparent;
    }
  }
}

.avatar-editor {
  .dialog{
    min-width: 400px;
  }
}

