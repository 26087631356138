@import 'variables/colors';
@import 'variables/typography';
@import 'utils';

.table2 {
  --mat-table-row-item-label-text-font: Montserrat, sans-serif;
  --mat-table-footer-supporting-text-font: Montserrat, sans-serif;
  --mat-table-header-headline-font: Montserrat, sans-serif;

  background-color: unset !important;
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0 2px !important;
  thead {
    tr {
      height: 44px;
      th {
        border: none;
        color: $color-ink-normal;
        font-size: $typography-em2-font-size;
        font-weight: $typography-em2-font-weight;
        line-height: $typography-em2-line-height;
      }
    }
  }
  tbody {
    border-radius: 8px;
    font-size: $typography-body2-font-size;
    font-weight: $typography-body2-font-weight;
    line-height: $typography-body2-line-height;
    tr {
      padding-bottom: 2em;
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 8px;
          }
          &:last-child {
            border-bottom-right-radius: 8px;
          }
        }
      }
      td {
        border: none;
        background: $color-white;
      }
    }
  }
}
