@import '../variables/colors';
@import '../variables/typography';
@import 'bootstrap/scss/mixins';
@import 'variables/colors';

// This is used only in 'app-address' component
.input {
  .mdc-text-field--filled {
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
    --mdc-filled-text-field-container-color: transparent;
    --mdc-filled-text-field-label-text-font: Montserrat, sans-serif;
    --mat-form-field-container-height: 42px;

    color: $color-ink-normal !important;
  }

  .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    --mat-form-field-subscript-text-font:  Montserrat, sans-serif;
  }

  .mat-mdc-form-field {
    font-family: $document-font-family !important;
    box-sizing: border-box;

    &.input-full-width {
      width: 100%;
    }

    &.mat-focused {
      .mat-mdc-form-field-flex {
        border: 1px solid $color-product-normal;
        box-sizing: border-box;
        box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
      }
    }

    .mat-mdc-form-field-flex {
      background: $color-white;
      border: 1px solid $color-neutral-normal;
      outline: none;
      border-radius: 8px;
      box-shadow: none;
      padding: 0 12px !important;
      margin: 0;
      align-items: center;
    }

    .mat-mdc-form-field-infix {
      border-top: 3px solid transparent;
      padding: 9px 0 1px 0;
      width: 100%;
      min-height: 44px;
    }

    .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
      margin: 0;
      position: relative;
    }

    .mat-error {
      color: $color-error;
      padding-left: 14px;
      padding-right: 8px;
      padding-top: 2px;
      font-style: normal;
      font-size: $input-error-font-size;
      line-height: $input-error-line-height;
    }

    .mat-hint {
      color: $color-gray;
      padding-left: 14px;
      padding-right: 8px;
      padding-top: 2px;
      font-style: normal;
      font-size: $body3-font-size;
      line-height: $body3-line-height;
    }

    .password-label {
      font-size: 1rem;
    }

    .mat-mdc-floating-label {
      color: $color-ink-normal !important;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: $input-title-font-size !important;
      line-height: $input-title-line-height !important;
      font-family: $document-font-family !important;
      width: 100%;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-1.5em) scale(1) !important;
      padding-top: 0 !important;
      font-size: $input-title-font-size-floating !important;
      line-height: $input-title-line-height-floating !important;
    }

    .mat-mdc-input-element {
      color: $color-ink-normal;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 14px !important;
      line-height: $input-content-line-height !important;
      &:not(.input-label-empty) {
        margin-top: 8px;
      }
    }
    .required-asterisk {
      color: $color-error;
    }

    // Styles for disabled input
    &.mat-mdc-form-field-disabled {
      .mat-mdc-floating-label {
        color: $color-gray;
      }

      .mat-mdc-input-element {
        color: $color-gray;
      }
    }

    // End Styles for disabled input

    // Styles for error input
    &.mat-form-field-invalid {
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;

        .mat-error {
          padding-top: 4px;
          padding-bottom: 6px;
          margin-bottom: -8px;
        }
      }

      .mat-mdc-floating-label {
        color: $color-error !important;
      }

      .mat-mdc-form-field-flex {
        border-color: $color-error;
      }
    }

    // End Styles for error input

    &:not(.mat-form-field-invalid) {
      .mat-mdc-text-field-wrapper {
        padding-bottom: 16px;
      }
    }
  }

  &.input-password {
    .mat-mdc-input-element {
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .input-textarea-autosize {
    overflow: hidden;
  }

  textarea {
    margin-bottom: 8px !important;
  }

  // Styles for suffix
  .input-suffix {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;

    &.input-suffix-text {
      cursor: pointer;
    }

    &.input-suffix-icon {
      margin-bottom: 2px;
      margin-right: 0;
      display: inline-block !important;
      cursor: pointer;
    }
  }

  // End Styles for suffix

  &.checkin {
    .mat-mdc-form-field-flex {
      border-radius: 8px 0 0 8px;
    }
  }

  &.checkout {
    .mat-mdc-form-field-flex {
      border-radius: 0 8px 8px 0;
    }
  }

  &.input-password-hints {
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0.5rem !important;
    }
  }

  .hints-panel {
    padding-left: 10px;
    padding-bottom: 1rem;

    .hints-panel-row {
      line-height: 1rem;

      .hints-panel-row-hint {
        font-size: 0.75rem;
        display: flex;
      }
    }

    .hints-panel-row-delimiter {
      height: 0.375rem;
    }
  }

  .forgot-password {
    margin-bottom: 1.5rem;
    margin-top: 0.7rem;
  }

  .autocomplete-content-width {
    width: unset;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: unset;
  }
}

.input-guests {
  position: relative;
  .mat-mdc-form-field {
    min-width: 130px;

    .mat-mdc-form-field-flex {
      padding-right: 8px !important;
    }
  }
}
.input-guests-menu-container {
  margin-top: -14px;
  width: 100%;
  background: $color-white;
  border: 1px solid $color-neutral-normal;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 10px;
}
.input-guests-menu-content {
  .input-guests-menu-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// input-guests mobile changes
@include media-breakpoint-down(md) {
  // Overlay panel after guests-overlay - overlay panel, that contains .mat-menu-panel.guests-panel
  .guests-overlay + * > .cdk-overlay-pane {
    width: 100%;
    padding: 0 16px;
  }
  .mat-menu-panel.guests-panel {
    width: 100%;
    max-width: unset;
  }
}

.mat-menu-panel.guests-panel {
  .input-guests-menu-section.mobile-border-bottom {
    border-bottom: 1px solid #e8e9eb;
  }
}

@include media-breakpoint-down(md) {
  .input {
    .mat-mdc-form-field {
      font-family: $document-font-family;
      box-sizing: border-box;

      .mat-mdc-input-element {
        color: $color-black;
        font-style: normal;
        font-weight: normal;
        font-size: $input-content-font-size-mobile;
        line-height: $input-content-line-height-mobile;
        margin-top: 8px;
      }
    }
  }
}

.common-input {
  border-radius: 8px;
  border: 1px solid $color-neutral-normal;
  overflow: hidden;

  &.focused {
    border-color: $color-product-normal;
    box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
  }

  .right-separator {
    border-right: 2px solid $color-neutral-normal;
    align-self: stretch;
  }

  input-text .mat-mdc-form-field {
    &.mat-focused {
      .mat-mdc-form-field-flex {
        border: none;
        box-shadow: none;
        border-radius: none;
      }
    }

    .mat-mdc-form-field-flex {
      border: none;
      box-shadow: none;
      border-radius: none;
    }
  }
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: unset !important;
}
