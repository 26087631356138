@import "colors";

@mixin reset-button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: inherit;
}

@mixin hide-scrollbar {
  // Hide content scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
