@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Variables
@import "variables/colors";
@import "variables/typography";
@import "variables/spacing";
@import "variables/general";

@import "./variables/bootstrap";
@import "./variables/utils";

// Bootstrap configuration
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Bootstrap components
@import "bootstrap/scss/root";
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/containers';

// Create alias for new Boostrap 5 classes 'ms, me, ps, pe' to 'ml, mr, pl, pr'
$utilities: map-merge(
    $utilities, (
    'margin-right': map-merge(
        map-get($utilities, 'margin-end'),
        ( class: mr ),
    ),
    'margin-left': map-merge(
        map-get($utilities, 'margin-start'),
        ( class: ml ),
    ),
    'padding-right': map-merge(
        map-get($utilities, 'padding-end'),
        ( class: pr ),
    ),
    'padding-left': map-merge(
        map-get($utilities, 'padding-start'),
        ( class: pl ),
    ),
  )
);

@import 'bootstrap/scss/utilities/api';

// Document
html {
  font-size: $document-font-size;
}

*, *::before, *::after {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

label {
  margin-bottom: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: $body1-font-size;
  font-weight: $body1-font-weight;
  line-height: $body1-line-height;
  color: $color-black;
  background: $color-background;
  touch-action: manipulation;
}

// Components
@import "component/typography";
@import "component/autocomplete";
@import "component/card";
@import "component/icon";
@import "component/button";
@import "component/dialog";
@import "component/input";
@import "component/single-number";
@import "component/notification";
@import "component/stars";
@import "component/avatar";
@import "component/mobile-tab";
@import "component/dropdown";
@import "component/checkbox";
@import "component/radio-button";
@import "component/switch";
@import "component/hint";
@import "component/accordion";
@import "component/state-indicator";
@import "component/notification-provider";
@import "component/block-ui";
@import "component/wizard-layout.component";
@import "component/review";
@import "component/user-settings";
@import "component/menu";
@import "component/chip";
@import "component/badge";
@import "component/increment-stepper";
@import "component/range-datepicker";
@import "component/amenity-menu";
@import "component/status";
@import "component/app-bar.component";
@import "component/tooltip";
@import "component/slider";

.divider {
  border-top: 1px solid $color-lightgrey;
}

.minimal-standard {
  // TODO: Move to amenities related stylesheet
  background: $color-primary-info;
  border: 1px solid $color-primary-info;
  border-radius: 8px;

  &.minimal-standard-icon {
    display: flex;

    label {
      padding-right: 8px;
    }
  }
}

.page-container {
  max-width: 1360px;
  margin: auto;
}

.cdk-overlay-backdrop {
  background: rgba($color-black, 0.8);
}

.mat-mdc-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
}

.container {
  padding-left: 40px;
  padding-right: 40px;

  @include media-breakpoint-down(md) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

// Authentication mobile dialogs
.title-position {
  margin-bottom: 2rem;
}
// END - Authentication mobile dialogs

// Post registration dialogs
.reg-container-half-btn {
  width: 50%;
  display: inline-block;
}

.reg-container-close-btn {
  position: relative;
}

.reg-close-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.upload-photo-avatar {
  height: 200px;
  width: 200px;
  min-width: 200px;

  @include media-breakpoint-down(md) {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
}
// END - Post registration dialogs

.card-table {
  background: transparent;
  border-spacing: 0 0.75rem !important;
  thead {
    tr {
      height: 32px;
      th {
        border: none;
      }
    }
  }
  tbody {
    tr {
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
      border-radius: 1rem;
      td {
        border: none;
        background: white;
        &:first-child {
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
        &:last-child {
          border-top-right-radius: 1rem;
          border-bottom-right-radius: 1rem;
        }
      }
    }
  }
}

.my-properties-table {
  tbody {
    tr {
      td {
        .my-properties-table-hover {
          visibility: hidden;
        }
      }
    }
    tr:hover {
      td {
        background-color: #F3FDFF;
        .my-properties-table-hover {
          visibility: visible;
        }
      }
    }
  }
}

.my-properties-filter-overlay-container {
  max-height: calc(calc(var(--vh, 1vh) * 100) - 200px);
}

.table {
  // TODO: reuse this table also for my properties
  --mat-table-row-item-label-text-font: Montserrat, sans-serif;
  --mat-table-footer-supporting-text-font: Montserrat, sans-serif;
  --mat-table-header-headline-font: Montserrat, sans-serif;

  background-color: unset !important;
  width: 100%;
  border-spacing: 0 8px !important;
  thead {
    tr {
      th {
        text-align: left;
        font-size: $body2-font-size;
        font-weight: $body2-font-weight;
        line-height: $body2-line-height;
        color: $color-black;
        padding: 0 16px;
        border-bottom: none;
        &:last-child {
          text-align: right;
        }
        .sort-column-header {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  tbody {
    tr {
      height: 56px;
      border-radius: 8px;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
      background: $color-white;
      overflow: hidden;
      td {
        font-size: $body2-font-size;
        font-weight: $body2-font-weight;
        line-height: $body2-line-height;
        color: $color-black;
        padding: 0 16px;
        text-align: left;
        border: none;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.w-100 {
  width: 100%;
}
// TODO check bootstrap class
.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.vacay-iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 91%;
  width: 100%;
  margin-top: 80px;
  border: none;
}

.nowrap {
  white-space: nowrap;
}


.mobile-edit-image-dialog {
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;

  z-index: 1100;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;

  .mobile-edit-image-dialog-close {
    flex: 1 1 auto;
  }

  .mobile-controls-container {
    padding:0.75rem;
    .mobile-control-group {
      border-radius: 8px;
      overflow: hidden;
      margin:8px 4px 4px 4px;
      background-color: transparent;

      .mobile-control {
        display: block;
        border-bottom: 1px solid rgba(17, 17, 17, 0.5);
        border-radius: unset;
        background: $color-white;
        color: $color-product-normal;

        &.mobile-control-critical {
          color: $color-red-normal;
        }
      }
    }
  }
}

.search-map {
  height: 100%;
  width: 100%;
  position: relative;
  .search-map-content {
    width: 100%;
    height: 100%;
  }

  .search-map-poi {
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 242px;
    padding: 8px 16px;
    border: 2px solid $color-secondary;
    border-radius: 10px;
    background: $color-white;

    .search-map-poi-toggle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      padding: 0;
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      top: 16px;
      right: 16px;
      left: auto;
      height: 48px;
      width: 48px;
      margin: 0;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .search-map-poi-toggle {
        min-width: 48px;
        height: 48px;
      }
    }
  }
  .search-map-navigate {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $color-secondary;
    border-radius: 24px;
    background: $color-white;
    cursor: pointer;
    z-index: 2;
  }
}

.blue-box {
  background-color: #EDF9FB;
  border-radius: 8px;
  padding: 8px 16px;
}

.cursor-pointer {
  cursor: pointer !important;
  * {
    cursor: pointer !important;
  }
}

.button-reset {
  @include reset-button();
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.break-word {
  word-break: break-word;
}

@include media-breakpoint-down(md) {
  .mobile-hidden {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .desktop-hidden {
    display: none !important;
  }
}

.dropdown-menu {
  text-align: center;
  margin: 0;
  background: $color-white;
  border: 1px solid $color-lightgrey;
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  overflow: hidden;
  height: auto;
  .menu-item {
    @extend .button-reset;
    @extend .typography;
    @extend .typography-variant-body1;
    color: $color-black;
    width: 100%;
    cursor: pointer;
    text-align: left;
    padding: 14px 20px 14px 12px;
    &:focus, &:hover, &.active {
      background: $color-secondary-light;
    }
  }

  @include media-breakpoint-down(md) {
    width: calc(100vw - 32px);
  }
}

.text-right {
  text-align: right !important;
}
