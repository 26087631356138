@import "../variables/colors";
@import "../variables/typography";

.menu {
  height: 24px;
  margin: 0 !important;
  padding: 0 !important;
}

.mat-mdc-menu-panel {
  border-radius: 8px !important;
  margin-top: 10px !important;
}

.mat-mdc-menu-content {
  padding: 0 !important;
  background-color: buttonface;
}

.mat-mdc-menu-item {
  color: $color-black;
  font-style: normal;
  font-weight: normal;

  &:hover {
    background: rgba(20, 152, 158, 0.2) !important;
    color: $color-secondary;
  }

  .mat-mdc-menu-item-text {
    font-family: $document-font-family !important;
    font-size: $input-content-font-size !important;
    line-height: $input-content-line-height !important;
  }
}
