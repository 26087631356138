@import "../variables/colors";
@import "../variables/typography";

.accordion {

  background: $color-secondary-light;
  border-radius: 0.5rem;

  .accordion-header {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .accordion-title {
      display: flex;
      align-items: center;
      user-select: none;

      .tip-icon {
        margin-left: -8px;
      }
    }

    .accordion-icon {
      height: 1.5rem;
      width: 1.5rem;
      transform: none;

      &.accordion-icon-opened {
        transform: rotate(-180deg);
      }
    }

    .accordion-title-text {
      margin-left: 0.25rem;
      margin-right: 0.75rem;
    }
  }

  .accordion-content {
    padding: 0 1.25rem 0.5rem;

    ul {
      padding-left: 5px;
      padding-right: 5px;

      li {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-left: 1rem;
      }
    }
  }

  .hollow-content-container {
    padding: 1px;
  }

  .hollow-content {
    background: $color-white;
    padding: 0 1.25rem 0.5rem;
  }
}
