@import "../variables/colors";

.review {
  border-bottom: 1px solid #D7D7D7;

  .review-user {
    .avatar {
      height: 32px;
      width: 32px;
      margin: 8px;
    }
    .user-data {
      justify-content: center;

      a {
        text-decoration: none;
      }
    }
  }

  .property-image-preview {
    width: 150px;
    height: 75px;
  }
}

.property-image-preview {
  border: 1px solid rgba(92, 92, 92, 0.1);
  box-sizing: border-box;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  border-radius: 8px;
  overflow: hidden;
  width: 341px;
  height: auto;
  max-height: 242px;

  &.property-image-preview-mobile {
    width: calc(100% + 2rem);
    margin: -16px;
    max-height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  position: relative;
  padding-top: 50%;

  .property-image-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    text-align: center;

    img {
      border-radius: inherit;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .image-overlay {
      position: absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;

      background-color: $color-primary;
      opacity: 30%;
    }

    .label {
      display: block;
      position: absolute;

      text-align: left;
      left: 1.25rem;
      bottom: 1.25rem;
      border-radius: 0.25rem;
      padding: 0.25rem;
    }
  }
}
