/**

  This SCSS file contains utility functions

 */
@use "sass:math";

/** This function will remove all units from value */
@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

/** Convert px value to rem value (24px -> 1.5rem) */
@function rem($pixels) {
  @return #{math.div(stripUnit($pixels), 16)}rem;
}
