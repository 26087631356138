@import '../variables/colors';
@import '../variables/typography';
@import '../variables/bootstrap';
@import '~tippy.js/dist/tippy.css';

body {
  .tippy-box {
    background: $color-primary;
    border-radius: 8px;
    padding: 8px 12px;
    color: $color-white;
    border: 0;
    font-family: $document-font-family;
    font-style: normal;
    font-weight: $body1-font-weight;
    font-size: $body1-font-size;
    line-height: $body1-line-height;
    white-space: pre-line;
    box-shadow: none;
    max-width: 400px;
    z-index: 2000;

    > .tippy-arrow {
      color: $color-primary;
    }
  }

  @include media-breakpoint-down(md) {
    .tippy-box {
      padding: 16px;
    }
  }
}
