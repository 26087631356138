// Variables
@import "../variables/colors";
@import "../variables/general";
@import "bootstrap/scss/mixins";

.property-wizard-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  a .icon {
    vertical-align: middle;
    margin-top: -3px;
  }

  .wizard-navbar-shadow-box {
    // Any better solution to make shadow look same way on bottom of whole navbar?
    z-index:500;
    top:0;
    position: fixed;
    width:100%;
    height: $menu-height;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
  }

  .wizard-container {
    flex: 1 0 auto;
    display:flex;
    flex-direction: row;


    .wizard-navbar {
      z-index: 1000;
      flex: 0 0 $menu-height;
      position: sticky;
      top: 0;

      background-color: $color-white;
    }

    .wizard-left {
      min-width: 280px;
      flex: 0 1 400px;

      display:flex;
      flex-direction: column;
      background: $color-white;

      .wizard-navbar {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        border-bottom-left-radius: 10px;

        .navbar-left-button {
          display: flex;
          margin-left: 2.5rem;
        }
      }
      .wizard-progress {
        position: fixed;
        top: $menu-height;

        overflow: hidden;

        .wizard-progress-content {
          margin: 56px 0 0 64px;
          .wizard-progress-element {
            margin-bottom: 1.75rem;
          }
        }

      }
      @include media-breakpoint-down(md) {
        .wizard-navbar {
          .navbar-left-button {
            display: flex;
            margin-right: 1.5rem;
          }
        }
        .wizard-progress {
          top: $menu-height-mobile;
        }
      }
    }

    .wizard-right {
      flex: 1 1 auto;

      display:flex;
      flex-direction: column;

      .wizard-navbar {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        border-bottom-right-radius: 10px;

        .navbar-right-button {
          margin-right: 2.5rem;
        }
        @include media-breakpoint-down(md) {
          .navbar-right-button {
            margin-right: 1.5rem;
          }
          &.wizard-right-container {
            border-bottom-left-radius: 10px;
          }
        }
      }
      .wizard-content {
        flex-shrink: 1;
        max-width: 900px;
        padding-top: 56px;
        background: $color-background;

        .wizard-input-group {
          max-width: 660px;
        }
      }
      @include media-breakpoint-down(md) {
        .wizard-content {
          padding-top: 24px;
        }
      }

      /* Shared for both navbar and content components */
      .wizard-right-container {
        padding-left: 100px;
        padding-right: 16px;
      }
      @include media-breakpoint-down(lg) {
        .wizard-right-container {
          padding-left: 16px;
        }
      }
    }
  }

  .wizard-background-overlay {
    position: fixed;
    left: 0;
    z-index: 1000;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba($color-black, 0.8);
  }

  .wizard-progress-bottom {
    z-index: 1000;
    flex: 0 0 $menu-height;
    position:sticky;

    display:flex;
    flex-direction: column;
    bottom: 0;

    background-color: $color-white;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 10px 10px 0 0;

    .wizard-sections-panel {
      flex: 0 0 auto;
      padding: 1rem;

      .wizard-progress-element {
        padding: 1rem;
      }

    }
    .wizard-bottom-navigation {
      display: flex;
      height: $menu-height;

      padding: 0 1.5rem;
      line-height: 1rem;

      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      .wizard-bottom-control {
        flex: 1 1 30%;
        display: inline-block;

        &.wizard-bottom-control-back {
          text-align: start;
        }

        &.wizard-bottom-control-progress {
          flex-basis: 40%;
          text-align: center;
        }

        &.wizard-bottom-control-next {
          text-align: end;
        }
      }
    }

    .inline-indication {
      display:inline-flex;
      padding: 0 0.25rem;
    }
  }
  @include media-breakpoint-down(md) {
    .wizard-navbar-shadow-box {
      height: $menu-height-mobile;
    }
    .wizard-container {
      .wizard-navbar {
        flex: 0 0 $menu-height-mobile;
      }
    }
    .max-width-xs-visible {
      flex: 0 0 $menu-height-mobile;
    }

    .wizard-progress-bottom {
      flex: 0 0 $menu-height-mobile;
      .wizard-bottom-navigation {
        height: $menu-height-mobile;
      }
    }
  }
}

.max-width-xs-visible {
  display: none;
}
@include media-breakpoint-down(sm) {
  .max-width-xs-visible {
    display: block;
  }
}

.min-width-xs-visible {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
@include media-breakpoint-down(sm) {
  .min-width-xs-visible {
    display: none;
  }
}

.wizard-notifications {
  position: fixed;
  top: $menu-height;
  left: 0;
  right: 0;
  z-index: 100;
  .notification-content {
    text-align: center;
    padding: 1rem;
  }
}
@include media-breakpoint-down(md) {
  .wizard-notifications {
    top: $menu-height-mobile;
  }
}

.w-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  .w-navigation {
    flex: 0 2 25%;
    background: $color-white;
    min-height: calc(var(--vh, 1vh) * 100 - 80px);
  }

  .w-content {
    flex: 2 1 75%;
    padding: 40px;
    margin-top: 8px;
    max-width: 1040px;
  }
}

@include media-breakpoint-down(md) {
  .w-container {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 2rem 1rem;
  }
}

.wizard-bottom-padding {
  padding-bottom:2.5rem;
}

.hostfully-cant-edit-card {
  background-color: $color-tertiary-light;
  border: 1px solid $color-tertiary;
  border-radius: 8px;
}
