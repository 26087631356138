@import "../variables/colors";
@import "../variables/typography";
@import "../variables/spacing";
@import "variables/colors";

.chip {
  display: block;
  border: 1px solid $color-blue-normal;
  color: $color-blue-normal;
  background: $color-white;
  font-weight: $body1-font-weight;
  font-size: $body1-font-size;
  line-height: $body1-line-height;
  min-height: 2rem;
  border-radius: 100px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  .chip-container {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 2rem;
  }

  &:hover {
    border-color: $color-blue-normal-hover;
    color: $color-blue-normal-hover;
  }

  &.chip-active {
    border-color: $color-blue-normal-hover;
    color: $color-white;
    background: $color-blue-normal;

    &:hover {
      border-color: $color-blue-normal-hover;
      color: $color-white;
    }
  }

  &.disabled {
    cursor: inherit;

    &:not(.chip-active) {
      border-color: $color-gray;
      color: $color-gray;
      cursor: default;
    }
  }

  .chip-icon {
    margin-right: 6px;
    margin-left: 2px;
  }

  .chip-close-button {
    margin-left: 4px;
    margin-right: -2px;
    color: inherit;
  }
}


