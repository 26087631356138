// Variables
@import "../variables/colors";

.radio-button {

  box-sizing: border-box;
  display: block;


  .radio-button-label {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: calc(18px + 0.5rem);
    min-height: 22px;

    &.radio-button-disabled {
      cursor: auto;
      opacity: .4;
    }

    .radio-button-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .radio-button-mark {
      position: absolute;
      top: 1px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $color-gray;
      border-radius: 20px;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:not(.radio-button-disabled):hover {
      .radio-button-input ~ .radio-button-mark {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    &.radio-button-disabled {
      .radio-button-mark {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .radio-button-mark:after {
      content: "";
      position: absolute;
      display: none;
    }

    & .radio-button-input:checked ~ .radio-button-mark:after {
      display: block;
    }

    .radio-button-mark:after {
      height: 14px;
      width: 14px;
      background-image: url('~src/assets/icon/radio_button.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
