// Variables
@import "../variables/colors";

.card {
  overflow: hidden;
  border: 1px solid rgba(92, 92, 92, 0.1);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: $color-white;

  .card-content {
    padding: 1rem;
  }
}
