@import "../variables/colors";
@import "../variables/typography";
@import "bootstrap/scss/mixins";
@import "variables/colors";

.dropdown {
  .mdc-text-field--filled {
    --mdc-filled-text-field-active-indicator-height: 0;
    --mdc-filled-text-field-focus-active-indicator-height: 0;
    --mdc-filled-text-field-container-color: transparent;
    --mdc-filled-text-field-focus-label-text-color: inherit;
    --mdc-filled-text-field-label-text-font: Montserrat, sans-serif;

    color: $color-ink-normal !important;
  }

  .mat-mdc-select {
    --mat-select-trigger-text-font:  Montserrat, sans-serif;
    --mat-select-trigger-text-size: 0.875rem;
  }

  .mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    --mat-form-field-subscript-text-font:  Montserrat, sans-serif;
  }

  .mat-mdc-form-field {

    font-family: $document-font-family !important;
    box-sizing: border-box;

    &.dropdown-full-width {
      width: 100%;
    }

    &.mat-focused {
      .mat-mdc-form-field-flex {
        border: 1px solid $color-product-normal;
        box-sizing: border-box;
        border-radius: 8px;
      }
    }

    .mat-mdc-form-field-flex {
      background: $color-white;
      border: 1px solid $color-neutral-normal;
      outline: none;
      border-radius: 8px;
      padding: 0 12px !important;
      margin: 0;
      align-items: center;
      min-width: 150px;
    }

    .mat-mdc-form-field-infix {
      border-top: 3px solid transparent;
      padding: 9px 0 1px 0;
      width: 100%;
      min-height: 44px;
    }

    .mat-mdc-floating-label {
      color: $color-ink-normal;
      font-style: normal;
      font-weight: bold;
      font-size: $input-title-font-size;
      line-height: $input-title-line-height;
      top: 20px !important;
    }

    .mat-select {
      margin-top: 8px;
    }

    .mat-select-value {
      font-family: $document-font-family;
      color: $color-black;
      font-style: normal;
      font-weight: normal;
      font-size: $input-content-font-size;
      line-height: $input-content-line-height;
    }

    .mat-mdc-select-arrow-wrapper {
      transform: translateY(-12px) !important;
    }

    .mat-mdc-select-arrow {
      border: 0;
      width: 3px;
      height: 0;
      margin-top: 6px;

      &:after {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        margin-top: -9px;
        margin-left: -26px;
        background: url('~src/assets/icon/chevron_down.svg');
      }

      &>svg {
        display: none;
      }
    }

    .mat-mdc-form-field-underline {
      display: none;
    }

    .mat-mdc-form-field-subscript-wrapper {
      margin: 0;
      position: relative;
    }
    .mat-error {
      color: $color-error;
      padding-left: 14px;
      padding-right: 8px;
      padding-top: 2px;
      font-style: normal;
      font-size: $input-error-font-size;
      line-height: $input-error-line-height;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-1.6em) scale(1);
        padding-top: 3px;
        font-size: $input-title-font-size-floating;
        line-height: $input-title-line-height-floating;
      }

    // Styles for disabled input
    &.mat-mdc-form-field-disabled {

      .mat-mdc-floating-label {
        color: $color-gray;
      }

      .mat-select-value {
        color: $color-gray;
      }

      .mat-mdc-select-arrow {
        border-image-source: url('~src/assets/icon/chevron_down_disabled.svg');
      }
    }
    // End Styles for disabled input

    // Styles for error input
    &.mat-form-field-invalid {
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;

        .mat-error {
          padding-top: 4px;
          padding-bottom: 6px;
          margin-bottom: -8px;
        }
      }

      .mat-mdc-floating-label {
        color: $color-error !important;
      }

      .mat-mdc-form-field-flex {
        border-color: $color-error;
      }
    }

    // End Styles for error input

    &:not(.mat-form-field-invalid) {
      .mat-mdc-text-field-wrapper {
        padding-bottom: 16px;
      }
    }
  }

  &.dropdown-time {
    .mat-mdc-form-field {
      .mat-mdc-select-arrow {
        &:after {
          background: url('~src/assets/icon/clock.svg') no-repeat center;
        }
      }
    }
  }
}

.mat-select-panel-wrap {
  margin-top: -12px;
}

.mat-mdc-autocomplete-panel {
  margin-top: 4px;
}

.mat-mdc-select-panel, .mat-mdc-autocomplete-panel {
  border-radius: 0.5rem !important;
  border: 1px solid rgba(92, 92, 92, 0.10);
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
  margin-top: 1px
}

@include media-breakpoint-down(md) {
  .input {

    .mat-mdc-form-field {
      font-family: $document-font-family;
      box-sizing: border-box;

      .mat-mdc-input-element {
        color: $color-black;
        font-style: normal;
        font-weight: normal;
        font-size: $input-content-font-size-mobile;
        line-height: $input-content-line-height-mobile;
        margin-top: 8px;
      }
    }
  }
}
.mat-mdc-option {
  font-family: $document-font-family !important;
  font-size: $body1-font-size !important;
  font-weight: $body1-font-weight !important;
  line-height: 100% !important;
  color: $color-black !important;
}
.mat-mdc-option:not(.mat-mdc-option-disabled) {
  background: white;
}
// Styles for background of mat-option during hover
.mat-mdc-option:hover:not(.mat-mdc-option-disabled) {
  color: $color-secondary;
  background: $color-secondary-light !important;
}
.mat-mdc-option.mat-active:not(.mat-mdc-option-disabled) {
  color: $color-secondary;
  background: $color-secondary-light !important;
}
// End Styles for background of mat-option during hover
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
  color: $color-ink-normal !important;
}

.mat-mdc-select-trigger {
  margin-top: 6px !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after  {
 color: inherit !important;
}

.mat-mdc-option:focus.mdc-list-item, .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background: $color-secondary-light !important;
  color: $color-secondary !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 2px !important;
  padding-bottom: 0 !important;
}

.dropdown2 .mat-mdc-form-field .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-select-arrow:after {
  margin-top: -3px !important;
}
