@import '../variables/colors';

.mat-mdc-slider.mat-primary {
  .mdc-slider__track--active_fill {
    background-color: $color-primary;
    border-color: $color-primary;
  }
  .mdc-slider__track--inactive,
  .mdc-slider__track--active {
    background-color: $color-gray;

    --mdc-slider-inactive-track-height: 2px;
    --mdc-slider-active-track-height: 2px;
  }
  .mdc-slider__thumb-knob {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
  }
  input {
    left: 0 !important;
    width: 100% !important;
  }
}
