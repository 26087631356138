@import "spacing";

$enable-flex:               true;
$enable-grid-classes:       true;

$spacers: (
  0: 0,
  4: 4px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  28: 28px,
  32: 32px,
  40: 40px,
  48: 48px,
  56: 56px,
  64: 64px,
  96: 96px,
  160: 160px,
);

$gutters: $spacers;

$grid-breakpoints: (
  xs: $breakpoint-xs,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
);
$container-max-widths: (
  xs: $breakpoint-sm,
  sm: $breakpoint-md,
  md: $breakpoint-lg,
  lg: $breakpoint-xl,
);
$grid-gutter-width: 16px;

@import "bootstrap/scss/mixins";
