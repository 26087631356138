// Variables
@import "../variables/colors";
@import "../variables/typography";

$status-colors: (
  "primary": $color-primary,
  "secondary": $color-secondary,
  "tertiary": $color-tertiary,
  "success": $color-success,
  "warning": $color-warning,
  "error": $color-error,
  "gray": $color-gray
);

.status {

  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $body3-font-size;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 12px;
  color: white;
  padding: 0 8px;

  @each $name, $hex in $status-colors {
    &.status-color-#{$name} {
      background-color: $hex;
    }
  }
}
