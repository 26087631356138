// Variables
@import "../variables/colors";
@import "../variables/typography";
@import "../variables/bootstrap";

.notification {
  overflow: hidden;
  color: $color-white;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;

  .notification-content {
    padding: 8px;
    width: 100%;
    text-align: center;
    color: $color-white;
    word-break: break-word;
  }

  &.notification-color-success {
    background: $color-success;
  }

  &.notification-color-warning {
    background: $color-warning;
  }

  &.notification-color-error {
    background: $color-error;
  }

  &.notification-color-info {
    background: $color-primary;
  }

  .notification-button {
    margin: 0 20px 0 10px;
  }

  .notification-button-space {
    margin-left: 54px;
  }

  @include media-breakpoint-down(md) {
    .notification-button {
      margin: 0;
    }

    .notification-button-space {
      margin-left: 0;
    }
  }



}
