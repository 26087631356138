@import "../variables/colors";
@import "../variables/typography";
@import "../variables/spacing";

.badge {
  justify-content: center;
  color: $color-black;
  padding: 5px 8px;
  display: inline-flex;
  flex-direction: row;
  font-weight: $body1-font-weight;
  font-size: $body1-font-size;
  line-height: $body1-line-height;
  border: 1px solid $color-white;
  min-height: 2rem;
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: $color-white;
}
