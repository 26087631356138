// Variables
@import "../variables/colors";
@import "../variables/typography";
@import "bootstrap/scss/mixins";
@import "variables/colors";

.typography {
  font-family: $document-font-family;
  text-align: inherit;
  margin: 0;
  color: $color-black;

  // Variant
  &.typography-variant-h1 {
    font-size: $heading1-font-size;
    font-weight: $heading1-font-weight;
    line-height: $heading1-line-height;
  }

  &.typography-variant-h2 {
    font-size: $heading2-font-size;
    font-weight: $heading2-font-weight;
    line-height: $heading2-line-height;
  }

  &.typography-variant-h3 {
    font-size: $heading3-font-size;
    font-weight: $heading3-font-weight;
    line-height: $heading3-line-height;
  }

  &.typography-variant-h4 {
    font-size: $heading4-font-size;
    font-weight: $heading4-font-weight;
    line-height: $heading4-line-height;
  }

  &.typography-variant-subheading1 {
    font-size: $subheading1-font-size;
    font-weight: $subheading1-font-weight;
    line-height: $subheading1-line-height;
  }

  &.typography-variant-subheading2 {
    font-size: $subheading2-font-size;
    font-weight: $subheading2-font-weight;
    line-height: $subheading2-line-height;
  }

  &.typography-variant-subheading3 {
    font-size: $subheading3-font-size;
    font-weight: $subheading3-font-weight;
    line-height: $subheading3-line-height;
  }

  &.typography-variant-body1 {
    font-size: $body1-font-size;
    font-weight: $body1-font-weight;
    line-height: $body1-line-height;
  }

  &.typography-variant-body2 {
    font-size: $body2-font-size;
    font-weight: $body2-font-weight;
    line-height: $body2-line-height;
  }

  &.typography-variant-body3 {
    font-size: $body3-font-size;
    font-weight: $body3-font-weight;
    line-height: $body3-line-height;
  }

  &.typography-variant-button {
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
  }

  &.typography-variant-link1 {
    font-size: $link1-font-size;
    font-weight: $link1-font-weight;
    line-height: $link1-line-height;
    font-style: normal;
    color: $color-product-normal;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-product-normal-hover;
    }

    &:focus {
      color: $color-product-normal-active;
    }

    &.typography-color-primary {
      color: $color-product-normal;

      &:hover {
        color: $color-product-normal-hover;
      }
    }

    &.typography-color-darkgray {
      color: $color-darkgray;

      &:hover {
        color: $color-primary-dark;
      }
    }

    &.typography-color-black {
      color: $color-black;

      &:hover {
        color: $color-primary-dark;
      }
    }
  }

  &.typography-variant-link2 {
    font-size: $link2-font-size;
    font-weight: $link2-font-weight;
    line-height: $link2-line-height;
    font-style: normal;
    color: $color-secondary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-secondary-dark;
    }

    &:focus {
      color: $color-secondary-dark;
    }

  }

  &.typography-variant-link3 {
    font-size: $link3-font-size;
    font-weight: $link3-font-weight;
    line-height: $link3-line-height;
    font-style: normal;
    color: $color-secondary;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-secondary-dark;
    }

    &:focus {
      color: $color-secondary-dark;
    }

  }

  &.typography-variant-navbarLink {
    font-size: $navbarLink-font-size;
    font-weight: $navbarLink-font-weight;
    line-height: $navbarLink-line-height;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:focus {
      color: $color-product-normal;
    }

    &.active {
      color: $color-product-normal;
    }
  }

  @include media-breakpoint-down(md) {
    &.typography-variant-navbarLink {
      font-weight: $navbarLink-font-weight-mobile;
      &.active {
        font-weight: $navbarLink-font-weight;
        border-bottom: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &.typography-variant-link1 {
      font-size: $link1-font-size-mobile;
      font-weight: $link1-font-weight;
      line-height: $link1-line-height-mobile;
      font-style: normal;
      color: $color-secondary;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: $color-secondary-dark;
      }

      &:focus {
        color: $color-secondary-dark;
      }
    }
  }

  &.typography-color-inherit {
    color: inherit;
  }

  &.typography-color-primary {
    color: $color-black;
  }

  &.typography-color-secondary {
    color: $color-product-normal;
  }

  &.typography-color-tertiary{
    color: $color-tertiary;
  }

  &.typography-color-warning {
    color: $color-yellow-normal;
  }

  &.typography-color-error {
    color: $color-red-normal;
  }

  &.typography-color-success {
    color: $color-green-normal;
  }

  &.typography-color-black {
    color: $color-black;
  }

  &.typography-color-white {
    color: $color-white;
  }

  &.typography-color-gray {
    color: $color-gray;
  }

  &.typography-color-darkgray {
    color: $color-ink-normal;
  }

  // Align
  &.typography-align-inherit {
    text-align: inherit;
  }

  &.typography-align-left {
    text-align: left;
  }

  &.typography-align-center {
    text-align: center;
  }

  &.typography-align-right {
    text-align: right;
  }

  &.typography-align-justify {
    text-align: justify;
  }

  // Transform

  &.typography-transform-uppercase {
    text-transform: uppercase;
  }

  // Gutter bottom
  &.typography-gutter-bottom {
    margin-bottom: 0.35em;
  }

  a & {
    text-decoration: none;
    cursor: pointer;
  }

  &.inline-row {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}


@include media-breakpoint-down(md)  {
  .typography {
    text-align: inherit;
    margin: 0;
    color: $color-black;

    // Variant
    &.typography-variant-h1 {
      font-size: $heading1-font-size-mobile;
      font-weight: $heading1-font-weight;
      line-height: $heading1-line-height-mobile;
    }

    &.typography-variant-h2 {
      font-size: $heading2-font-size-mobile;
      font-weight: $heading2-font-weight;
      line-height: $heading2-line-height-mobile;
    }

    &.typography-variant-h3 {
      font-size: $heading3-font-size-mobile;
      font-weight: $heading3-font-weight;
      line-height: $heading3-line-height-mobile;
    }

    &.typography-variant-h4 {
      font-size: $heading4-font-size-mobile;
      font-weight: $heading4-font-weight;
      line-height: $heading4-line-height-mobile;
    }

    &.typography-variant-subheading2 {
      font-size: $subheading2-font-size-mobile;
      font-weight: $subheading2-font-weight;
      line-height: $subheading2-line-height;
    }

    &.typography-variant-body1 {
      font-size: $body1-font-size-mobile;
      font-weight: $body1-font-weight;
      line-height: $body1-line-height-mobile;
    }
  }
}
