@import "../variables/colors";

.hint {
  font-size: 0.875rem;
  line-height: 1rem;

  .hint-indicator {
    display:inline-block;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid $color-black;
    margin: 0.25rem 0.5rem 0 0.5rem;
  }

  &.hint-variant-none {
    .hint-indicator {
      border-color: $color-gray;
      background-color: $color-gray;
    }
  }

  &.hint-variant-default {
    .hint-indicator {
      border-color: $color-gray;
      background-color: $color-gray;
    }
  }

  &.hint-variant-valid {
    .hint-indicator {
      border-color: $color-success;
      background-color: $color-success;
    }
  }

  &.hint-variant-invalid {
    .hint-indicator {
      border-color: $color-error;
      background-color: $color-error;
    }
  }

}
