/* montserrat-300 - latin_latin-ext */
@font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-regular - latin_latin-ext */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-500 - latin_latin-ext */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-600 - latin_latin-ext */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-700 - latin_latin-ext */
  @font-face {
    font-display: swap; 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* montserrat-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* montserrat-900 - latin_latin-ext */
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('~src/assets/fonts/montserrat/montserrat-v26-latin_latin-ext-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}