// Variables
@import "../variables/colors";
@import "../variables/typography";

.menu {
  .menu-icon {
    position: relative;
    cursor: pointer;
  }
  
  .amenity-checkbox {
    margin: 12px;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
  }

  .amenity-menu-container {
    position: absolute;
    left: 0;
    width: 100%;
    background: $color-white;
    border: 1px solid rgba($color-black, 0.1);
    box-sizing: border-box;
    box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 10;
  }
}
