@import 'fonts/montserrat';
@import 'fonts/material-icons';

@import "table";
@import "variables/colors";
@import "variables/typography";

.search-map-property-marker {
  width: auto;
  height: auto;

  .search-map-property-marker-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;
    background: $color-ink-normal;
    border-radius: 10px;
    padding: 12px ;
    color: $color-white;
    text-align: center;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
  }

  .search-map-property-marker-arrow {
    display: block;
    width: 14px;
    height: 10px;
    margin: auto;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid $color-ink-normal;
  }
}

.search-map-property-marker {
  z-index: 1;
  cursor: pointer;

  &:hover {
    z-index: 3;
  }

  &.active {
    z-index: 2;
  }

  &:hover,
  &.active {
    .search-map-property-marker-body {
      background: $color-product-normal;
    }

    .search-map-property-marker-arrow {
      border-top: 8px solid $color-product-normal;
    }
  }
}
