// Variables
@import "variables/colors";
@import "../variables/typography";

.button {
  font-family: $document-font-family;
  font-weight: $button-font-weight;
  font-size: $button-font-size;
  line-height: $button-line-height;
  border-radius: 8px;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.button-variant-contained {
    color: $color-white;

    &.button-size-small {
      padding: 8px 12px;
    }

    &.button-size-normal {
      padding: 12px 20px;
    }

    &.button-size-large {
      padding: 20px 32px;
    }

    &.button-color-primary {
      color: $color-white;
      background: $color-product-normal;
      &:hover:not(&:disabled) {
        background: $color-product-normal-hover;
      }
      &:active {
        background: $color-product-normal-active;
      }
    }
    &.button-color-secondary {
      color: $color-ink-normal;
      background: $color-neutral-light;
      &:hover:not(&:disabled) {
        background: darken($color-neutral-light-hover, 15%);
      }
      &:active {
        background: darken($color-neutral-light-active, 15%);
      }
    }
    &.button-color-warning {
      background: $color-yellow-normal;
      &:hover:not(&:disabled) {
        background: darken($color-yellow-normal-hover, 15%);
      }
      &:active {
        background: darken($color-yellow-normal-active, 15%);
      }
    }
    &.button-color-white {
      background: $color-white;
      color: $color-ink-normal;
      &:hover:not(&:disabled) {
        background: $color-neutral-lighter;
      }
      &:active {
        background: $color-neutral-light;
      }
    }
    &.button-color-background {
      background: $color-neutral-light;
      &:hover:not(&:disabled) {
        background: darken($color-neutral-light-hover, 15%);
      }
      &:active {
        background: darken($color-neutral-light-active, 15%);
      }
    }


    &:disabled {
      cursor: default;
      opacity: 0.5;
    }

  }

  &.button-variant-outlined {
    background: transparent;
    color: $color-ink-normal;
    border: 2px solid;

    &.button-size-small {
      padding: 6px 10px;
    }

    &.button-size-normal {
      padding: 10px 18px;
    }

    &.button-size-large {
      padding: 19px 31px;
    }

    &.button-color-primary {
      color: $color-product-normal;
      border-color: $color-product-normal;
      &:hover:not(&:disabled) {
        border-color: $color-product-normal-hover;
        color: $color-product-normal-hover;
      }
      &:active {
        border-color: $color-product-normal-active;
        color: $color-white;
        background: $color-product-normal-active;
      }
    }
    &.button-color-secondary {
      color: $color-ink-normal;
      border-color: $color-neutral-light;
      &:hover:not(&:disabled) {
        border-color: $color-neutral-light-hover;
        color: $color-ink-normal;
      }
      &:active {
        border-color: $color-neutral-light-active;
        color: $color-ink-normal;
        background: $color-neutral-light-active;
      }
    }
    &.button-color-warning {
      color: $color-yellow-normal;
      border-color: $color-yellow-normal;
      &:hover:not(&:disabled) {
        border-color: $color-yellow-normal-hover;
        color: $color-yellow-normal-hover;
      }
      &:active {
        border-color: $color-yellow-normal-active;
        color: $color-white;
        background: $color-yellow-normal-active;
      }
    }
    &.button-color-white {
      color: $color-white;
      border-color: $color-white;
      &:hover:not(&:disabled) {
        border-color: darken($color-white, 3%);
        color: darken($color-white, 3%);
      }
      &:active {
        border-color: darken($color-white, 3%);
        color: darken($color-white, 3%);
      }
      &:disabled {
        background: transparent;
        &:hover:not(&:disabled) {
          background: transparent;
        }
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.button-variant-float {
    background: $color-white;
    color: $color-product-normal;
    border: 2px solid $color-product-normal;

    &.button-size-small {
      padding: 6px 10px;
    }

    &.button-size-normal {
      padding: 10px 18px;
    }

    &.button-size-large {
      padding: 19px 31px;
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.button-variant-link {
    background: transparent;
    color: $color-product-normal;
    margin: 0 4px;
    padding: 0 2px 5px 2px;
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: 2px solid;
    border-bottom-color: transparent;

    &:hover:not(&:disabled) {
      border-bottom: 2px solid;
    }

    &.button-color-primary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
        border-bottom-color: $color-product-normal-hover;
      }
      &:active {
        color: $color-product-normal-active;
        border-bottom-color: $color-product-normal-active;
      }
    }
    &.button-color-secondary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
        border-bottom-color: $color-product-normal-hover;
      }
      &:active {
        color: $color-product-normal-active;
        border-bottom-color: $color-product-normal-active;
      }
    }
    &.button-color-warning {
      color: $color-yellow-normal;
      &:hover:not(&:disabled) {
        color: $color-yellow-normal-hover;
        border-bottom-color: $color-yellow-normal-hover;
      }
      &:active {
        color: $color-yellow-normal-active;
      }
    }
    &.button-color-white {
      color: $color-white;
      &:hover:not(&:disabled) {
        color: darken($color-white, 3%);
        border-bottom-color: darken($color-white, 3%);
      }
      &:active {
        color: darken($color-white, 3%);
      }
    }
    &.button-color-ink {
      color: $color-ink-normal;
      &:hover:not(&:disabled) {
        color: $color-ink-normal;
        border-bottom-color: $color-ink-normal
      }
      &:active {
        color: $color-ink-normal;
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.button-variant-text-clear {
    background: transparent;
    color: #337A87;
    margin: 0 4px;
    padding: 0 2px 5px 2px;
    border-radius: 0;
    box-sizing: border-box;

    &.button-size-normal {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
    }

    &.button-size-large {
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
    }

    &.button-color-primary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
        border-radius: 10px;
      }
      &:active {
        color: $color-product-normal-active;
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &.button-color-secondary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
        border-radius: 10px;
      }
      &:active {
        color: $color-product-normal-active;
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  &.button-variant-clear {
    background: transparent;
    color: $color-product-normal;
    padding: 0 2px 5px 2px;
    border-radius: 0;
    box-sizing: border-box;

    &.button-color-primary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
      }
      &:active {
        color: $color-product-normal-active;
      }
    }
    &.button-color-secondary {
      color: $color-product-normal;
      &:hover:not(&:disabled) {
        color: $color-product-normal-hover;
      }
      &:active {
        color: $color-product-normal-active;
      }
    }
    &.button-color-warning {
      color: $color-yellow-normal;
      &:hover:not(&:disabled) {
        color: $color-yellow-normal-hover;
      }
      &:active {
        color: $color-yellow-normal-active;
      }
    }
    &.button-color-white {
      color: $color-white;
      &:hover:not(&:disabled) {
        color: $color-neutral-lighter
      }
      &:active {
        color: $color-neutral-light
      }
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.button-variant-icon {
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &.button-color-primary {
      color: $color-product-normal;
    }
    &.button-color-secondary {
      color: $color-product-normal;
    }
    &.button-color-warning {
      color: $color-yellow-normal;
    }
    &.button-color-white {
      color: $color-white;
    }
    &.button-color-inherit {
      color: inherit;
    }

    &:disabled {
      cursor: default;
    }
  }

  &.button-variant-link-active {
    background: transparent;
    color: $color-product-normal;
    margin: 0 4px;
    padding: 0 2px 5px 2px;
    border-radius: 0;
    box-sizing: border-box;
    border-bottom: 2px solid;

    &:disabled {
      color: $color-neutral-normal;
      border-bottom-color: $color-neutral-normal;
      cursor: default;
    }
  }

  &.button-variant-mobile-control {
    background: $color-white;
    color: $color-product-normal;

    &.button-color-warning {
      color: $color-red-normal;
    }

    &:disabled {
      color: $color-neutral-normal;
      border-bottom-color: $color-neutral-normal;
      cursor: default;
    }

    &.button-size-small {
      padding: 6px 10px;
    }

    &.button-size-normal {
      padding: 10px 18px;
    }

    &.button-size-large {
      padding: 19px 31px;
    }
  }

  &.button-full-width {
    width: 100%;
  }

  &.button-loading {
    .button-loading-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      background-color: inherit;
      align-items: center;
      border-radius: inherit;
    }
  }
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  button {
    cursor: pointer;
    overflow: hidden;
    width: 50%;
    height: 48px;
    text-align: center;
    border: 2px solid $color-product-normal;
    background: transparent;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-line-height;
    color: $color-product-normal;
    &.selected {
      background: $color-product-normal;
      color: $color-white;
    }
  }
  &.button-group-1 {
    button {
      border-radius: 0.5rem;
      margin: 0 -1px;
    }
  }
  &.button-group-2 {
    button:nth-child(1) {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      margin-right: -1px;
    }
    button:nth-of-type(2) {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      margin-left: -1px;
    }
  }
  &.button-group-4 {
    button:nth-child(1) {
      border-top-left-radius: 0.5rem;
      margin-bottom: -1px;
      margin-right: -1px;
    }
    button:nth-of-type(2) {
      border-top-right-radius: 0.5rem;
      margin-bottom: -1px;
      margin-left: -1px;
    }
    button:nth-of-type(3) {
      border-bottom-left-radius: 0.5rem;
      margin-top: -1px;
      margin-right: -1px;
    }
    button:nth-of-type(4) {
      border-bottom-right-radius: 0.5rem;
      margin-top: -1px;
      margin-left: -1px;
    }
  }
}
