// Variables
@import "../variables/colors";

.switch {

  box-sizing: border-box;
  display: block;
  width: max-content;

  .switch-label {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 60px;
    min-height: 32px;

    &.switch-disabled {
      cursor: auto;
    }

    .switch-input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .switch-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 32px;
      width: 60px;
      background-color: $color-gray;
      border-radius: 24px;
      transition: 200ms;
      &:after {
        content: "";
        display: block;
        position: absolute;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        top: 2px;
        margin-left: 2px;
        background-color: #FCFCFC;
        transition: 200ms;
      }
    }

    & .switch-input:checked ~ .switch-mark {
      background-color: $color-secondary;
    }

    & .switch-input:checked ~ .switch-mark:after {
      margin-left: 26px;
    }

    &.switch-disabled {
      .switch-mark {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
}
