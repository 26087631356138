// Variables
@import "../variables/colors";
@import "../variables/typography";

.stars {
  display: flex;

  .stars-icons {
    display: flex;
    align-items: center;
  }

  .stars-text {
    margin: 1px 0 0 0.25rem;
  }

  .grey-star {
    opacity: 0.4;
  }
}
