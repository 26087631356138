// Variables
@import "../variables/colors";
@import "../variables/typography";
@import "variables/colors";

.icon {
  display: inline-block;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;

  &.icon-size-x-small {
    height: 0.75rem;
    width: 0.75rem;
    min-width: 0.75rem;
  }

  &.icon-size-small {
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
  }

  &.icon-size-medium {
    height: 1.5rem;
    width: 1.5rem;
    min-width: 1.5rem;
  }

  &.icon-size-medium-large {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }

  &.icon-size-large {
    height: 3rem;
    width: 3rem;
    min-width: 3rem;
  }

  & .icon-color-primary {
    color: $color-ink-normal;
  }
  & .icon-color-secondary {
    color: $color-secondary;
  }
  & .icon-color-inherit {
    color: inherit;
  }
  & .icon-color-warning {
    color: $color-yellow-normal;
  }
  & .icon-color-error {
    color: $color-error;
    fill: $color-error;
  }
  & .icon-color-tertiary {
    color: $color-tertiary;
  }
  & .icon-color-tertiary-bright {
    color: $color-tertiary-bright;
  }
  & .icon-color-gray {
    color: $color-gray;
  }
  & .icon-color-white {
    color: $color-white;
  }
  & .icon-color-yellow {
    color: $color-yellow;
  }
  & .icon-color-success {
    color: $color-success;
  }
  & .icon-color-star {
    color: $color-star;
  }
  & .icon-color-black {
    color: $color-black;
  }

  .icon-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &[stroke] {
      stroke: currentColor;
    }

    &[fill] {
      fill: currentColor;
    }
  }
}
