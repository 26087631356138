@import 'src/styles/variables/colors';
@import 'bootstrap/scss/mixins';

.mat-mdc-dialog-container {
  border: none;
  border-radius: 0 !important;
  box-shadow: none;
  padding: 0 !important;

  .mat-mdc-dialog-surface {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.dialog-wrapper {
  display: flex;
  height: calc(var(--vh, 1vh) * 100);
  justify-content: center;
}

.dialog {
  max-height: calc(calc(var(--vh, 1vh) * 100) - 100px);
  border: 1px solid rgba(92, 92, 92, 0.1);
  box-sizing: border-box;
  box-shadow: 0 3px 16px 1px rgba(0, 0, 0, 0.25);
  background: $color-white;
  
  &.dialog-position-center {
    align-self: center;
    margin: 16px;
    border-radius: 10px;
    &.dialog-width-xs {
      width: 320px;
    }
    &.dialog-width-s {
      width: 400px;
    }
    &.dialog-width-sm {
      width: 500px;
    }
    &.dialog-width-md {
      width: 768px;
    }
    &.dialog-width-lg {
      width: 992px;
    }
    &.dialog-width-xl {
      width: 1200px;
    }
  }

  &.dialog-position-top {
    align-self: flex-start;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }

  &.dialog-position-bottom {
    align-self: flex-end;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  &.dialog-layout-title-content {
    display: grid;
    grid-template-rows: min-content minmax(0, 1fr);
  }

  &.dialog-layout-title-content-actions {
    display: grid;
    grid-template-rows: min-content minmax(0, 1fr) min-content;
  }

  &.dialog-layout-content-actions {
    display: grid;
    grid-template-rows: minmax(0, 1fr) min-content;
  }

  .dialog-layout-content {
    padding: 16px;
  }
}

.dialog-background {
  background: rgba($color-black, 0.8);
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 32px 32px 0 32px;

  @include media-breakpoint-down(md) {
    padding: 16px 16px 0 16px;
  }
}

.dialog-content {
  display: block;
  padding: 16px 32px 24px 32px;
  overflow-y: auto;

  @include media-breakpoint-down(md) {
    padding: 16px 16px 8px 16px;
  }

  .notification {
    border-radius: 8px;
  }
}

.dialog-actions {
  width: 100%;
  display: block;
  padding: 8px 32px 32px 32px;
  @include media-breakpoint-down(md) {
    padding: 8px 16px 24px 16px;
  }
}
