@import "src/styles/variables/colors";
@import "src/styles/variables/typography";
@import "src/styles/variables/bootstrap";

.datepicker-container {
 position: relative;
 padding: 0 8px;
}

.date-picker-overlay {
  background: $color-white;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .date-picker-inputs {
    padding: 16px;
    width: 512px;
  }
  .calendars-container-no-overlay {
    width: fit-content;
    padding: 0px 17px 5px;
  }
}

.calendars-container {
  width: fit-content;
  background: $color-white;
  margin-top: -14px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 17.5px 15px 5px;
}

.calendars-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 9.5px;
}

.calendars {
  display: flex;
}

.calendars-divider {
  min-width: 32px;
}

.calendar-month-text {
  width: 184px;
  text-align: center;
}

.calendar-chevron {
  cursor: pointer;
}

// MOBILE VIEW

.datepicker-mobile-menu {
  height: 4.0625rem;
  position: sticky;
  width: 100%;
  z-index: 50;
  background-color: $color-white;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  padding: 0.5rem 0;

  &.up-menu {
    top: 0;
    border-radius: 0 0 10px 10px;

    .up-menu-content {
      display: flex;
      height: 100%;
      align-items: center;
    }
  }

  &.down-menu {
    bottom: 0;
    border-radius: 10px 10px 0 0;
    position: fixed;

    .down-menu-content {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;
    }
  }
}

.datepicker-mobile-view-container {
  overflow: auto;
  background: $color-background;
  border: 1px solid $color-gray;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  .datepicker-mobile-view-content {
    padding: 24px 16px;
    margin-bottom: 52px;

    .datepicker-mobile-calendar-container {
      text-align: center;
      margin-left: 8px;
    }
  }
}

.datepicker-mobil-buttons {
  display: flex;
  justify-content: space-between;
}

.datepicker-mobil-buttons-divider {
  min-width: 17px;
}

// MOBILE VIEW END

@include media-breakpoint-down(md) {
  app-calendar {
    width: 100%;
  }

  .calendars-container {
    width: 100%;
  }

  .calendars {
    justify-content: center;
  }
}
