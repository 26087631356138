// Variables
@import "../variables/colors";
@import "../variables/typography";

.state-indicator {
  display: flex;
  align-items: center;

  .state-indicator-label {
    margin-left: 12px;
  }
}
