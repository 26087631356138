$document-font-family: Montserrat, sans-serif;
$document-font-size: 16px;

$heading1-font-size: 3.5rem; // 56px
$heading1-font-weight: 700;
$heading1-line-height: 4.26625rem; //68.26px
$heading1-font-size-mobile: 2rem; //32px
$heading1-line-height-mobile: 2.4381rem; //39.01px

$heading2-font-size: 2.5rem; //40px
$heading2-font-weight: 700;
$heading2-line-height: 3.0475rem; //48.76px
$heading2-font-size-mobile: 2rem; //32px
$heading2-line-height-mobile: 2.4381rem; //39.01px

$heading3-font-size: 1.875rem; //30px
$heading3-font-weight: 700;
$heading3-line-height: 2.285625rem; //36.57px
$heading3-font-size-mobile: 2rem;
$heading3-line-height-mobile: 2.4381rem;

$heading4-font-size: 1.5rem; //24px
$heading4-font-weight: 700;
$heading4-line-height: 1.82875rem; //29.26px
$heading4-font-size-mobile: 1.5rem;
$heading4-line-height-mobile: 1.83rem;

$subheading1-font-size: 1rem; //16px
$subheading1-font-weight: 700;
$subheading1-line-height: 1.21875rem; //19.5px

$subheading2-font-size: 0.875rem; //14px
$subheading2-font-weight: 700;
$subheading2-line-height: 1.3125rem; //21px
$subheading2-font-size-mobile: 0.875rem; //14px

$subheading3-font-size: 0.75rem; //12px
$subheading3-font-weight: 700;
$subheading3-line-height: 1.125rem; //18px

$body1-font-size: 1rem; //16px
$body1-font-weight: 400;
$body1-line-height: 1.5rem; //24px
$body1-font-size-mobile: 1rem; //16px
$body1-line-height-mobile: 1.5rem; //24px

$body2-font-size: 0.875rem; //14px
$body2-font-weight: 400;
$body2-line-height: 1.3125rem; //21px

$body3-font-size: 0.75rem; //12px
$body3-font-weight: normal;
$body3-line-height: 1.125rem;

$link1-font-size: 1rem; //16px
$link1-font-weight: 600;
$link1-line-height: 1.5rem; //24px
$link1-font-size-mobile: 1rem;
$link1-line-height-mobile: 1.5rem;

$link2-font-size: 0.875rem; //14px
$link2-font-weight: 600;
$link2-line-height: 1.3125rem; //21px

$link3-font-size: 0.75rem; //12px
$link3-font-weight: normal;
$link3-line-height: 1.125rem;

$navbarLink-font-size: 1rem; //16px
$navbarLink-font-weight: 600;
$navbarLink-line-height: 1.5rem; //24px
$navbarLink-font-weight-mobile: 400;
$navbarLink-font-size-mobile: 1rem;

$button-font-size: 1rem; //16px
$button-font-weight: 600;
$button-line-height: 1.5rem; //24px

$input-title-font-size: 1rem;
$input-title-line-height: 1.5rem;
$input-title-font-size-floating: 0.875rem;
$input-title-line-height-floating: 1.3125rem;

$input-content-font-size: 1rem;
$input-content-line-height: 1.5rem;
$input-content-font-size-mobile: 1rem;
$input-content-line-height-mobile: 1.5rem;

$input-error-font-size: 0.75rem; // 12 px
$input-error-line-height: 1.125rem; // 18 px

$select-font-size: 1rem;
$select-font-weight: 600;
$select-line-height: 1.5rem;

$select-font-size: 1rem;
$select-font-weight: 600;
$select-line-height: 1.5rem;


