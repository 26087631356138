@import "../variables/colors";
@import "../variables/typography";
@import "../variables/bootstrap";

.single-number {
  background: $color-white;
  height: 3.75rem;
  width: 3.75rem;
  outline:none;
  border: 1px solid rgba(92, 92, 92, 0.1);
  box-sizing: border-box;
  box-shadow: 0 8px 13px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  text-align: center;

  font-family: $document-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: $document-font-size;
  line-height: 1.25rem;
  color: $color-primary;

  &:focus {
    border: 1px solid $color-primary;
  }
}

@include media-breakpoint-down(md) {
  .single-number {
    height: 3.125rem;
    width: 3.125rem;
  }
}
