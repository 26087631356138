@import "bootstrap/scss/mixins";
@import "variables/colors";

.settings-container {
  max-width: 840px;
}

.settings-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
}

.settings-row-base {
  display: flex;
  flex: 1 1 auto;

  .row-item-label {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;

    &.pb {
      padding-bottom: 16px;
    }
  }
}

.settings-row-item {
  @extend .settings-row-base;
  flex-direction: column;
}

.settings-flex-phone {
  flex: 1 1 auto;
}

@include media-breakpoint-up(md) {
  .row-label {
    flex: 0 0 150px;
  }
}

.setting-section {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .setting-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    .setting-label {
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      @include media-breakpoint-up(md) {
        flex: 0 0 150px;
      }

    }
    .setting-content {
      flex-grow: 1;
      @include media-breakpoint-up(md) {
        .setting-form {
          max-width: 500px;
          flex-direction: column;
          display: flex;
          gap: 8px;
          //row-item-content-pw: max 450px
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .settings-row-item {
    @extend .settings-row-base;
    flex-flow: row;

    .row-item-label {
      display: flex;
      flex-flow: row;
      margin-top: 2px;
      flex: 0 0 150px;

      &.pb {
        padding-bottom: 16px;
      }
    }

    .row-item-content {
      max-width: 500px;
    }

    .row-item-content-pw {
      max-width: 450px;
    }
  }

  .settings-flex-phone {
    flex: 1 1 75%;
  }
}

@include media-breakpoint-down(md) {
  .settings-row {
    margin: 16px 0;

    .row-item-label {
      margin-bottom: 6px;
      flex: 1 1;
      justify-content: space-between;
    }
  }
}

.integration-logo {
  display: flex;
  justify-content: center;
  min-width: 230px;
  max-height: 145px;
  margin-bottom: 16px;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}

@include media-breakpoint-down(lg) {
  .integration-logo {
    width: 50%;
    max-width: 200px;
  }
}

.integration-connected-card {
  border-radius: 10px;
  padding: 8px;
  background-color: $color-green-light;
  border: 3px solid $color-green-normal;

  &.disconnect {
    background-color: $color-red-light;
    border-color: $color-red-normal;
  }

  .integration-connected-card-label {
    padding: 8px;
  }

  .integration-connected-card-content {
    margin-left: 30px;
  }
}

.imported-properties-card {
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid $color-neutral-dark;
  background-color: $color-white;
  span,div {
    padding: 8px;
  }
}

.integration-ready-card {
  margin-bottom: 16px;
  border-radius: 10px;
  background-color: $color-green-light;
  div {
    margin: 8px;
  }
}

.integration-not-ready-card {
  padding: 8px;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid $color-neutral-dark;
  background-color: $color-yellow-light;
  div {
    padding: 8px;
    color: $color-yellow-normal;
  }
}

