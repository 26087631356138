$color-primary: #364A74;
$color-primary-dark: #29395A;
$color-primary-mint: #506DA6;
$color-primary-light: #b9c3d9;
$color-secondary: #337A87;
$color-secondary-dark: #2B636D;
$color-secondary-mint: #2691A5;
$color-secondary-light: #EDF9FB;
$color-tertiary: #B18E23;
$color-tertiary-light: #FBF0D1;
$color-tertiary-bright: #DDB12B;
$color-success: #43DD81;
$color-success-light: #ebfff9;
$color-warning: #F7854F;
$color-error: #EE5454;
$color-error-light: #FFEBEB;
$color-star: #F1C644;
$color-success: #11B483;

$color-white: #FFFFFF;
$color-yellow: #B18E23;
$color-black: #252A31;
$color-darkgray: #585C63;
$color-gray: #8B8F94;
$color-gray-light: #C8C8C8;
$color-green: #337A87;
$color-green-dark: #173F46;


$color-background: #FAFAFC;
$color-accordion: #BBDFE6;
$color-primary-info: #EDF5FC;
$color-lightgrey: #E8E9EB;
